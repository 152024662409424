const introduce = [
  "BI Engineer ",
  "Skilled  : Data Visualization, SQL ",
]

const bio =[
  "비즈니스 데이터를 다루며 매출 및 손익 분석 대시보드를 개발합니다.",
  // "SQL을 활용한 데이터 추출 및 시각화를 통해 인사이트 도출을 지원합니다.",
  "다양한 부서와 협업하며 데이터를 더욱 가치 있게 활용하는 데 열정을 가지고 있습니다. "
]

const urls = {
  github: "https://github.com/camiyoung",
  blog: "https://anji0.tistory.com/",
  email: "anji6978@naver.com",
  linkedIn : "https://www.linkedin.com/in/%EC%A7%80%EC%98%81-%EC%95%88-6ab6251a9/"
}

export { introduce, urls,bio }
