import { education, careers } from "../../data/experiences"
import {projects,project_past} from "../../data/projects"
import calculatePeriod from "../../utils/calculatePeriod.js"

 function ExperienceList({ title, list,isCareer }) {
  console.log(list)
  return (
    <div className="w-full p-4 border-b sm:flex border-neutral-100 text-neutral-700">
      <div className="font-bold sm:w-1/5">{title}</div>

      <ul className="space-y-3 sm:w-4/5 ">
        {list.map((item, idx) => (
          <li className="items-baseline space-x-1" key={idx}>
            <div className="justify-between mb-1 md:flex">
              <p className="mb-1 md:mb-0">
                <span className="font-bold "> {item.name}</span>{" "}
                <span className="block mr-1 text-xs font-extralight md:text-base text-neutral-500 sm:inline">
                  <span className="hidden font-bold sm:inline"> - </span>
                  <b >{item.title}</b>
                </span>
                {item.github && (
                  <a
                    href={item.github}
                    className="p-1 text-xs font-semibold bg-gray-100 rounded-xl"
                  >
                    Github Repo
                  </a>
                )}
              </p>
              <div className="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-3 h-3 mr-1 sm:w-4 sm:h-4 text-neutral-500"
                >
                  <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
                  <path
                    fillRule="evenodd"
                    d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
                    clipRule="evenodd"
                  />
                </svg>

                <span className="text-xs font-normal lg:text-sm ">
                  {item.period} {isCareer==='1' && "- 현재 재직중 ( "+calculatePeriod(item.period)+" )"}
                </span>
              </div>
            </div>
            <div className={list && list.length !== 1 ? "mb-4" : "mb-0"}>
              {item.brief && (
                <p className="mb-1 text-xs font-normal md:text-sm md:flex">
                  <span>{item.brief} </span>
                </p>
              )}
              {item.description &&
                item.description.map((desc, idx) => (
                  <p className="mb-1 text-xs font-medium md:text-sm" key={idx}>
                    <small className="font-extralight text-neutral-300">
                      ➜
                    </small>{" "}
                    {desc}
                  </p>
                ))}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

const Experience = (props) => (
  <div className="justify-center w-full max-w-5xl m-auto border-t-2 sm:p-5 sm:px-20 border-neutral-100">

    <div className="" id="careers">
      <ExperienceList title="Experience" list={careers} isCareer='1'/>
    </div>

    <div className="" id="project">
      <ExperienceList title="Projects (Data)" list={projects} />
    </div>

    <div className="" id="project">
      <ExperienceList title="Projects (Web) " list={project_past} />
    </div>
    <div className="" id="education">
      <ExperienceList title="Education" list={education} />
    </div>
  </div>
)

export default Experience
