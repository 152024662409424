/** 
* 시작 날짜부터 현재까지의 기간을 계산하는 함수
 * @param {string} startDate - 'YYYY.MM' 형식의 시작 날짜
 * @returns {string} '00년 00개월' 형식의 기간
 */
export default function calculatePeriod(startDate) {
  // 입력 형식 검증
  if (!/^\d{4}\.\d{2}$/.test(startDate)) {
    throw new Error("날짜 형식은 'YYYY.MM'이어야 합니다. (예: 2023.01)");
  }

  // 입력된 날짜 파싱
  const [year, month] = startDate.split('.').map(num => parseInt(num, 10));
  
  // 시작 날짜 객체 생성 (해당 월의 1일로 설정)
//   const startDateObj = new Date(year, month - 1, 1);
  
  // 현재 날짜 
  const currentDate = new Date();
  
  // 현재 날짜의 연도와 월
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  
  // 현재 달까지 포함하기 위해 현재 달에 +1 (진행 중인 달 포함)
  // 전체 개월 수 계산
  let totalMonths = (currentYear - year) * 12 + (currentMonth - month + 1);
  
  // 음수인 경우(미래 날짜) 처리
  if (totalMonths < 0) {
    throw new Error("시작 날짜는 현재 날짜보다 이전이어야 합니다.");
  }
  
  // 년과 월로 변환
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;
  
  // 결과 문자열 생성
  let result = '';
  
  if (years > 0) {
    result += `${years}년 `;
  }
  
  if (months > 0 || years === 0) {
    result += `${months}개월`;
  }
  
  return result.trim();
}