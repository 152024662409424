import { introduce, urls,bio } from "../../data/profile"
import Avatar from "../Avatar"

const URLButton = ({ url, text, color='default' }) => {
  const colorClasses ={
    default : 'border-violet-300 bg-violet-200 hover:bg-violet-300 text-violet-700',
    blue:'bg-blue-200 border-blue-300 hover:bg-blue-300 text-blue-700',
    gray:'bg-gray-200 border-gray-400 hover:bg-gray-300' ,
  }
  return (
    <a
      href={url}
      className={`inline-block px-3 py-1 text-sm transition duration-300 bg-opacity-50 border rounded-full text-primary-content  ${colorClasses[color]} `}
      target="_blank"
      rel="noreferrer"
    >
      <button>{text}</button>
    </a>
  )
}

function Info(props) {
  return (
    <div className="flex items-end justify-center w-full ">
      <div className="w-full ">
        <div className="flex flex-col justify-center w-full text-center sm:pl-2">
          <div className="items-baseline ">
            <h2 className="text-4xl font-extrabold text-neutral-700">
              JIYOUNG AHN
            </h2>{" "}
          </div>
          <p className="pt-2 mt-2 text-neutral-600">
            {introduce.map((line, i) => {
              return (
                <span key={i}>
                  {line}
                  <br />
                </span>
              )
            })}
          </p>
          <div className='flex justify-center'>
          <p className="pt-2 mt-2 text-sm font-normal text-neutral-600 alignt">
            {bio.map((line, i) => {
              return (
                <span key={i}>
                  {line}
                  <br />
                </span>
              )
            })}
          </p>
          </div>


          <div className="mt-4 space-x-2">
          <URLButton url={urls.linkedIn} text="LinkedIn"  />
          <URLButton url={urls.blog} text="Blog"  />
            <URLButton url={urls.github} text="Github" />
      
   
          </div>

        </div>
      </div>
    </div>
  )
}

function Profile(props) {
  return (
    <div className="relative w-full pt-12 pb-10 " id="about">
      <div className="absolute w-10/12 rounded-full sm:w-screen -rotate-6 bg-angular-gradient h-80 blur-3xl -top-40 -z-50"></div>
      <Info />
    </div>
  )
}

export default Profile
