const education = [
  {
    name: "삼성 청년 SW 아카데미",
    period: "2022.01 - 2022.12",
    title: ["Web Development : Fontend / Backend "],
    description :[ "진행한 4개의 프로젝트에서 모두 1등을 수상하며 상위 5%의 성적으로 수료하였습니다."]
  },
  {
    name: "서울시립대학교 ",
    title: "Bachelor of Computer Science and Engineering",
    period: "2016.03 - 2021.02",
    description :[ "컴퓨터과학부를 졸업 후 행정 조교로 근무하였습니다(2021.11월까지)"]
  },
]

const awards = [
  {
    name: "삼성 청년 SW아카데미 - 관통, 공통, 자율, 특화 프로젝트 ( 각각 1등 ) 및 전국 결선 입상",
    period: "2022.05~2022.12",
  },
  // {
  //   name: "삼성 청년 SW아카데미 특화 프로젝트 우수상 (1등)",
  //   period: "2022.10.07",
  // },
  // {
  //   name: "삼성 청년 SW아카데미 공통 프로젝트 우수상 (1등)",
  //   period: "2022.08.19",
  // },
  // {
  //   name: "삼성 청년 SW아카데미 관통 프로젝트 최우수상 (1등)",
  //   period: "2022.05.27",
  // },
]


const careers = [
  {
    name: "CJ OliveNetworks ",
    period: "2023.01",
    title: "BI Engineer  ·  SAP Solution Engineer ",
    description: [
      "SQL을 활용한 데이터 추출 및 시각화를 위한 View 개발",
      "데이터 시각화 (SAP Analytics Cloud)",
      "Date Warehouse 시스템 관리 - Data ETL 프로세스 및 정합성 관리",
      "현업 부서를 위한 데이터 시각화 교육"
    ],
    skills: [
      "SQL",
      "Database",
      "SAP BW",
      "SAP Analytics Cloud",
      "SAP Datasphere",
    ],
  },
]

export { education, awards, careers }
