const projects = [
  {
    id: 6,
    name: "국가간 판매 실적 및 비교 분석 대시보드 ",
    title: "BI Engineer / CJ OliveNetworks ",
    // github: "https://github.com/camiyoung/ZipZong",
    // skills: ["react", "redux", "tailwind"],
    brief: " 특정 제품의 판매 실적을 수출지/수입지 기준으로 분석 가능하도록 구현한 대시보드 ",
    description: [
      "실적 조회 자동화로 법인 간 거래 데이터를 직관적으로 시각화하여 분석 효율성 향상",
      "특정 제품의 수출국·수입국을 한눈에 파악할 수 있는 대시보드 개발로 데이터 조회 자동화 ",
      "수출지·수입지 기준의 가격 형성 적정성을 평가할 수 있도록 데이터 기반 의사 결정 지원",
      "2차에 걸쳐 구축. 1차: 2023.11 - 2024.02 / 2차  : 2024.11 - 2025.02" 
    ],
    period: "2024.11 - 2025.02",
  },
{
  id: 5,
  name: "전사 법인별 손익 대시보드",
  title: "BI Engineer / CJ OliveNetworks ",
  // github: "https://github.com/camiyoung/ZipZong",
  // skills: ["react", "redux", "tailwind"],
  brief: " 한국 본사 및 해외 법인별 Profit & Loss를 분석할 수 있는 대시보드",
  description: [
    "법인별 Sales, OP, GP, SG&A 등 주요 지표를 시각화하여 실적 분석을 직관적으로 개선",
    "법인별 실적 업로드 시 자동 반영되는 대시보드를 개발해 기존의 데이터 정리 및 보고 프로세스 혁신",
    "법인 간 표준화된 기준을 적용하여 신뢰도 높은 데이터 기반 의사 결정 지원  ",
  ],
  period: "2024.01 - 2024.10",
},
{
  id: 202407202411,
  name: "현업 데이터 시각화 생성 지원 ",
  title: "BI Engineer / CJ OliveNetworks ",
  // github: "https://github.com/camiyoung/ZipZong",
  // skills: ["react", "redux", "tailwind"],
  brief: "현업 부서의 사람이 데이터 시각화를 구축 할 수 있도록 부서별 교육 및 시각화 제작 지원 프로젝트 ",
  description: [
    "구매팀 : 자재별 실적 단가와 매출 단가 분석 시각화 제작. 수작업 프로세스 자동화 및 법인간 기준 통일화 ",
    "프로세스 혁신팀 : 고객 기준의 가치 분석을 위한 시각화 제작. 수작업 중심 보고 프로세스 효율화",
    "물류운영팀: 여러 루트별 물류비 실적 분석 및 계획버전별 달성비 조회 시각화. 수작업 중심 보고  프로세스 효율화 "
  ],
  period: "2024.07 - 2024.11",
},
{
  id: 202404202406,
  name: "자재 기준 현황 분석용 Data Mart 구축",
  title: "BI Engineer / CJ OliveNetworks ",
  // github: "https://github.com/camiyoung/ZipZong",
  // skills: ["react", "redux", "tailwind"],
  brief: "자재의 생성/현황/삭제/중복 등의 데이터를 조회할 수 있는 데이터 마트 구축 ",
  description: [
    "일별 자재 현황 상태 적재 프로세스 구축",
    "자재 및 거래처별 기준 정보 적재 테이블 및 데이터 적재 ",
  ],
  period: "2024.04 - 2024.06",
},
{
  id: 7,
  name: "영업 본부 매출 분석 대시보드",
  title: "BI Engineer / CJ OliveNetworks ",
  // github: "https://github.com/camiyoung/ZipZong",
  // skills: ["react", "redux", "tailwind"],
  brief: "영업 본부의 다양한 Sales 경로 별로 실적을 확인 할 수 있는 대시보드 ",
  description: [
    "일자별 매출 및 월별 마감 실적 조회 화면 개발 ",
    "영업 본부 내의 경로별 지표 조회 기준 적용 - B2B, B2C 등 분야별 하위 경로에 대한 조건 적용",
    "Sales, GP, OP 실적 Trend 및 전년비 분석 시각화 ",
  ],
  period: "2023.08 - 2023.10",
},
]

const project_past=[
  {
    id: 1,
    name: " 집에서 운동중 ",
    title: "Frontend Developer / SSAFY ",
    github: "https://github.com/camiyoung/ZipZong",
    skills: ["react", "redux", "tailwind"],
    brief: "화상 채팅을 통한 운동 수행 횟수 측정 서비스 ",
    description: [
      "Redux를 이용한 React 컴포넌트 전역 상태 관리 구현",
      "React Life Cycle 을 고려한 실시간 화상 채팅 기능 구현( with Open Vidu) ",
      "운동 동작 수행 count 측정 기능 구현 (with Google Teachable Machine )",
    ],
    period: "2022.07.05 - 2022.08.19",
  },
  {
    id: 2,
    name: " 드림멍즈",
    title: " Frontend Developer  / SSAFY",
    github: "https://github.com/camiyoung/DreamMungz",
    skills: ["react", "typescript", "react-query", "recoil", "tailwind"],
    period: "2022.08.22 - 2022.10.07",
    brief: "NFT 발급/거래 기능이 가능한 Web Game 서비스 ",
    description: [
      "필터 적용 검색 기능 구현. 34개의 필터 적용 및 해제 기능 구현 ",
      "재사용 가능한 Component 제작 (Pagenation, Tab, Badge, Card List)",
      "Three.js 을 이용한 3D 전시장 구현",
    ],
  },
  {
    id: 3,
    name: " Starry Night ",
    title: " Unity Developer / SSAFY",
    github: "https://github.com/camiyoung/StarryNight",
    skills: ["unity", "react"],
    period: "2022. 10 .11 - 2022. 11. 25",
    brief: "시간/위치 기반 3D 별자리 관측 서비스",
    description: [
      "현실의 시간/위치를 고려한 별자리 위치 계산 시스템 구현 ",
      "사용자간 멀티 플레이 기능 구현",
      "VR 기기 연동 플레이 기능 구현",
    ],
  },
  {
    id: 5,
    name: " 편한 집 찾기 ",
    title: "Full Stack Developer / SSAFY",
    github: "https://github.com/SSAFY7-16-7/HappyHouse",
    skills: ["vue", "vuex", "springboot", "mysql"],
    period: "2022.05.19 - 2022.05.26",
    brief: "공공 데이터와 지도 API를 활용한 거래 정보 조회 서비스",
    description: [
      "Kakao Map API 와 아파트 거래 공공 데이터를 활용한 거래 정보 표현",
      "Spring MVC 아키텍처를 적용한 게시글 작성 및 북마크 기능 구현",
    ],
  },

  {
    id: 4,
    name: " 실시간 채팅 기능 서비스  ",
    title: " Full Stack Developer / Personal Project",
    github: "https://github.com/camiyoung/chat-with-react",
    skills: ["react", "Node.js", "Express.js", "Socket.io"],
    period: "2021. 06",
    brief: "Socket.io를 활용한 채팅 서비스 ",
    description: ["채팅방 개설  및 실시간 대화 서비스 구현"],
  },
];
export  {projects,project_past}
